<template>
    <!-- About section start -->
	<section id="main-section" class="px-4 lg:px-32 py-8 relative">
        <div class="w-full flex justify-center items-center p-4 md:p-6">
            <div class="flex-col justify-center items-center cursor-pointer p-1" @click="flag = 'arg'">
                <div class="bg-[url('@/assets/flags/argentina.png')] bg-cover bg-center border-black border-2 h-10 w-10 lg:h-20 lg:w-20 m-auto"></div>
                <p class="text-black font-bold text-xxs md:text-sm p-1 text-center">Argentina</p>
            </div>

            <div class="flex-col justify-center items-center cursor-pointer p-1" @click="flag = 'sau'">
                <div class="bg-[url('@/assets/flags/saudi_arabia.webp')] bg-cover bg-center border-black border-2 h-10 w-10 lg:h-20 lg:w-20 m-auto"></div>
                <p class="text-black font-bold text-xxs md:text-sm p-1 text-center">Arabia Saudí</p>
            </div>

            <div class="flex-col justify-center items-center cursor-pointer p-1" @click="flag = 'mex'">
                <div class="bg-[url('@/assets/flags/mexico.png')] bg-cover bg-center border-black border-2 h-10 w-10 lg:h-20 lg:w-20 m-auto"></div>
                <p class="text-black font-bold text-xxs md:text-sm p-1 text-center">México</p>
            </div>

            <div class="flex-col justify-center items-center cursor-pointer p-1" @click="flag = 'pol'">
                <div class="bg-[url('@/assets/flags/polonia.png')] bg-cover bg-center border-black border-2 h-10 w-10 lg:h-20 lg:w-20 m-auto"></div>
                <p class="text-black font-bold text-xxs md:text-sm p-1 text-center">Polonia</p>
            </div>
        </div>

        <!--ARGENTINA-->
        <div v-show="flag === 'arg'" class="w-full flex justify-center">
            <div class="bg-[url('@/assets/background.png')] bg-cover bg-center md:max-w-sixty">
                <div class="w-full flex justify-center items-center">
                    <a v-for="(por,idx) in arg.por" :key="idx" :src="por.src" class="w-full m-2 cursor-pointer" style="max-width: 20%;" :href="por.src" :download="por.name" >
                        <img :src="por.src">
                    </a>
                </div>
                <div class="w-full flex justify-center items-center">
                    <a v-for="(def,idx) in arg.def" :key="idx" :src="def.src" class="w-full m-2 cursor-pointer" style="max-width: 20%;" :href="def.src" :download="def.name" >
                        <img :src="def.src">
                    </a>
                </div>
                <div class="w-full flex justify-center items-center">
                    <a v-for="(med,idx) in arg.med" :key="idx" :src="med.src" class="w-full m-2 cursor-pointer" style="max-width: 20%;" :href="med.src" :download="med.name" >
                        <img :src="med.src">
                    </a>
                </div>
                <div class="w-full flex justify-center items-center">
                    <a v-for="(del,idx) in arg.del" :key="idx" :src="del.src" class="w-full m-2 cursor-pointer" style="max-width: 20%;" :href="del.src" :download="del.name" >
                        <img :src="del.src">
                    </a>
                </div>
            </div>

            <div class="hidden md:flex flex-wrap "  style="max-width: 20%;">
                <a v-for="(ban,idx) in arg.ban" :key="idx" :src="ban.src" class="w-full m-2 cursor-pointer" style="max-width:35%;" :href="ban.src" :download="ban.name" >
                    <img :src="ban.src">
                </a>
            </div>
        </div>

        <!--SAUDI ARABIA-->
        <div v-show="flag === 'sau'" class="w-full flex justify-center">
            <div class="bg-[url('@/assets/background.png')] bg-cover bg-center md:max-w-sixty">
                <div class="w-full flex justify-center items-center">
                    <a v-for="(por,idx) in sau.por" :key="idx" :src="por.src" class="w-full m-2 cursor-pointer" style="max-width: 20%;" :href="por.src" :download="por.name" >
                        <img :src="por.src">
                    </a>
                </div>
                <div class="w-full flex justify-center items-center">
                    <a v-for="(def,idx) in sau.def" :key="idx" :src="def.src" class="w-full m-2 cursor-pointer" style="max-width: 20%;" :href="def.src" :download="def.name" >
                        <img :src="def.src">
                    </a>
                </div>
                <div class="w-full flex justify-center items-center">
                    <a v-for="(med,idx) in sau.med" :key="idx" :src="med.src" class="w-full m-2 cursor-pointer" style="max-width: 20%;" :href="med.src" :download="med.name" >
                        <img :src="med.src">
                    </a>
                </div>
                <div class="w-full flex justify-center items-center">
                    <a v-for="(del,idx) in sau.del" :key="idx" :src="del.src" class="w-full m-2 cursor-pointer" style="max-width: 20%;" :href="del.src" :download="del.name" >
                        <img :src="del.src">
                    </a>
                </div>
            </div>

            <div class="hidden md:flex flex-wrap "  style="max-width: 20%;">
                <a v-for="(ban,idx) in sau.ban" :key="idx" :src="ban.src" class="w-full m-2 cursor-pointer" style="max-width:35%;" :href="ban.src" :download="ban.name" >
                    <img :src="ban.src">
                </a>
            </div>
        </div>

        <!--MEXICO-->
        <div v-show="flag === 'mex'" class="w-full flex justify-center">
            <div class="bg-[url('@/assets/background.png')] bg-cover bg-center md:max-w-sixty">
                <div class="w-full flex justify-center items-center">
                    <a v-for="(por,idx) in mex.por" :key="idx" :src="por.src" class="w-full m-2 cursor-pointer" style="max-width: 20%;" :href="por.src" :download="por.name" >
                        <img :src="por.src">
                    </a>
                </div>
                <div class="w-full flex justify-center items-center">
                    <a v-for="(def,idx) in mex.def" :key="idx" :src="def.src" class="w-full m-2 cursor-pointer" style="max-width: 20%;" :href="def.src" :download="def.name" >
                        <img :src="def.src">
                    </a>
                </div>
                <div class="w-full flex justify-center items-center">
                    <a v-for="(med,idx) in mex.med" :key="idx" :src="med.src" class="w-full m-2 cursor-pointer" style="max-width: 20%;" :href="med.src" :download="med.name" >
                        <img :src="med.src">
                    </a>
                </div>
                <div class="w-full flex justify-center items-center">
                    <a v-for="(del,idx) in mex.del" :key="idx" :src="del.src" class="w-full m-2 cursor-pointer" style="max-width: 20%;" :href="del.src" :download="del.name" >
                        <img :src="del.src">
                    </a>
                </div>
            </div>

            <div class="hidden md:flex flex-wrap "  style="max-width: 20%;">
                <a v-for="(ban,idx) in mex.ban" :key="idx" :src="ban.src" class="w-full m-2 cursor-pointer" style="max-width:35%;" :href="ban.src" :download="ban.name" >
                    <img :src="ban.src">
                </a>
            </div>
        </div>

        <!--POLONIA-->
        <div v-show="flag === 'pol'" class="w-full flex justify-center">
            <div class="bg-[url('@/assets/background.png')] bg-cover bg-center md:max-w-sixty">
                <div class="w-full flex justify-center items-center">
                    <a v-for="(por,idx) in pol.por" :key="idx" :src="por.src" class="w-full m-2 cursor-pointer" style="max-width: 20%;" :href="por.src" :download="por.name" >
                        <img :src="por.src">
                    </a>
                </div>
                <div class="w-full flex justify-center items-center">
                    <a v-for="(def,idx) in pol.def" :key="idx" :src="def.src" class="w-full m-2 cursor-pointer" style="max-width: 20%;" :href="def.src" :download="def.name" >
                        <img :src="def.src">
                    </a>
                </div>
                <div class="w-full flex justify-center items-center">
                    <a v-for="(med,idx) in pol.med" :key="idx" :src="med.src" class="w-full m-2 cursor-pointer" style="max-width: 20%;" :href="med.src" :download="med.name" >
                        <img :src="med.src">
                    </a>
                </div>
                <div class="w-full flex justify-center items-center">
                    <a v-for="(del,idx) in pol.del" :key="idx" :src="del.src" class="w-full m-2 cursor-pointer" style="max-width: 20%;" :href="del.src" :download="del.name" >
                        <img :src="del.src">
                    </a>
                </div>
            </div>

            <div class="hidden md:flex flex-wrap "  style="max-width: 20%;">
                <a v-for="(ban,idx) in pol.ban" :key="idx" :src="ban.src" class="w-full m-2 cursor-pointer" style="max-width:35%;" :href="ban.src" :download="ban.name" >
                    <img :src="ban.src">
                </a>
            </div>
        </div>
		<!-- Main text end -->
	</section>
	<!-- About section end -->
</template>

<script>

export default {
    data() {
        return {
            flag: 'arg',
            arg: {
                del: [
                    {name: "Di María", src: require('../assets/ARG/Di María.jpg')},
                    {name: "Lautaro Mtez", src: require('../assets/ARG/Lautaro Mtez.jpg')},
                    {name: "Messi", src: require('../assets/ARG/Messi.jpg')},
                ],
                med: [
                    {name: "De Paul", src: require('../assets/ARG/De Paul.jpg')},
                    {name: "Enzo Fernandez", src: require('../assets/ARG/Enzo Fdez.jpg')},
                    {name: "Paredes", src: require('../assets/ARG/Paredes.jpg')}
                ],
                def: [
                    {name: "Acuña", src: require('../assets/ARG/Acuña.jpg')},
                    {name: "Cristian Romero", src: require('../assets/ARG/Cristian Romero.jpg')},
                    {name: "Gonzalo Montiel", src: require('../assets/ARG/Gonzalo Montiel.jpg')},
                    {name: "Otamendi", src: require('../assets/ARG/Otamendi.jpg')}
                ],
                por: [
                    {name: "EmiMtez", src: require('../assets/ARG/EmiMtez.jpg')}
                ],
                ban:[
                    {name: "Á.Correa", src: require('../assets/ARG/Á.Correa.jpg')},
                    {name: "Armani", src: require('../assets/ARG/Armani.jpg')},
                    {name: "Dybala", src: require('../assets/ARG/Dybala.jpg')},
                    {name: "Exe Palacios", src: require('../assets/ARG/Exe Palacios.jpg')},
                    {name: "Foyth", src: require('../assets/ARG/Foyth.jpg')},
                    {name: "Guido Rodguez", src: require('../assets/ARG/Guido Rodguez.jpg')},
                    {name: "Julián Alvrez", src: require('../assets/ARG/Julián Alvrez.jpg')},
                    {name: "Lisandro Mtez", src: require('../assets/ARG/Lisandro Mtez.jpg')},
                    {name: "Mac Allister", src: require('../assets/ARG/Mac Allister.jpg')},
                    {name: "Nahuel Molina", src: require('../assets/ARG/Nahuel Molina.jpg')},
                    {name: "Papu", src: require('../assets/ARG/Papu.jpg')},
                    {name: "Pezzella", src: require('../assets/ARG/Pezzella.jpg')},
                    {name: "Foyth", src: require('../assets/ARG/Foyth.jpg')},
                    {name: "Rulli", src: require('../assets/ARG/Rulli.jpg')},
                    {name: "Scaloni", src: require('../assets/ARG/Scaloni.jpg')},
                    {name: "Tagliafico", src: require('../assets/ARG/Tagliafico.jpg')},
                    {name: "Tiago Almada", src: require('../assets/ARG/Tiago Almada.jpg')}
                ]
            },
            sau: {
                del: [
                    {name: "Feras Albrikan", src: require('../assets/SAU/Feras Albrikan.jpg')},
                    {name: "Salem Al-Dawsari", src: require('../assets/SAU/Salem Al-Dawsari.jpg')},
                ],
                med: [
                    {name: "Abdulellah Al-Malki", src: require('../assets/SAU/Abdulellah Al-Malki.jpg')},
                    {name: "Mohamed Kanno", src: require('../assets/SAU/Mohamed Kanno.jpg')},
                    {name: "Salman Al-Faraj", src: require('../assets/SAU/Salman Al-Faraj.jpg')}
                ],
                def: [
                    {name: "Abdulelah Al-Amri", src: require('../assets/SAU/Abdulelah Al-Amri .jpg')},
                    {name: "Ali Al-Bulayhi", src: require('../assets/SAU/Ali Al-Bulayhi.jpg')},
                    {name: "Sultan Al-Ghannam", src: require('../assets/SAU/Sultan Al-Ghannam.jpg')},
                    {name: "Yasser Al-Shahrani", src: require('../assets/SAU/Yasser Al-Shahrani.jpg')}
                ],
                por: [
                    {name: "Mohammed Al-Owais", src: require('../assets/SAU/Mohammed Al-Owais.jpg')}
                ],
                ban:[
                    {name: "Abdullah Madu ", src: require('../assets/SAU/Abdullah Madu .jpg')},
                    {name: "Abdullah Otayf", src: require('../assets/SAU/Abdullah Otayf.jpg')},
                    {name: "Abdulrahman Al-Aboud", src: require('../assets/SAU/Abdulrahman Al-Aboud.jpg')},
                    {name: "Hatan Bahbri ", src: require('../assets/SAU/Hatan Bahbri .jpg')},
                    {name: "Herve Renard", src: require('../assets/SAU/Herve Renard.jpg')},
                    {name: "Mohammed Alburayk", src: require('../assets/SAU/Mohammed Alburayk.jpg')},
                    {name: "Nawaf Alaqidi ", src: require('../assets/SAU/Nawaf Alaqidi .jpg')},
                    {name: "Riyadh Sharahili", src: require('../assets/SAU/Riyadh Sharahili.jpg')},
                    {name: "Saleh Al-Shehri", src: require('../assets/SAU/Saleh Al-Shehri.jpg')},
                    {name: "Ali Al-Hassan", src: require('../assets/SAU/Ali Al-Hassan.jpg')},
                    {name: "Haitham Asiri", src: require('../assets/SAU/Haitham Asiri.jpg')},
                    {name: "Hassan Altambakti", src: require('../assets/SAU/Hassan Altambakti.jpg')},
                    {name: "Mohammed Alyami", src: require('../assets/SAU/Mohammed Alyami.jpg')},
                    {name: "Nasser Al-Dawsari", src: require('../assets/SAU/Nasser Al-Dawsari.jpg')},
                    {name: "Nawaf Alabid ", src: require('../assets/SAU/Nawaf Alabid .jpg')},
                    {name: "Sami Alnaji", src: require('../assets/SAU/Sami Alnaji.jpg')},
                    {name: "Saud Abdulhamid", src: require('../assets/SAU/Saud Abdulhamid.jpg')}
                ]
            },
            mex: {
                del: [
                    {name: "Alexis Vega", src: require('../assets/MEX/Alexis Vega.jpg')},
                    {name: "Hirving Lozano", src: require('../assets/MEX/Hirving Lozano.jpg')},
                    {name: "Raul Jimenez", src: require('../assets/MEX/Raul Jimenez.jpg')},
                ],
                med: [
                    {name: "Andres Guardado", src: require('../assets/MEX/Andres Guardado.jpg')},
                    {name: "Edson Alvarez", src: require('../assets/MEX/Edson Alvarez.jpg')},
                    {name: "Hector Herrera", src: require('../assets/MEX/Hector Herrera.jpg')}
                ],
                def: [
                    {name: "Cesar Montes", src: require('../assets/MEX/Cesar Montes.jpg')},
                    {name: "Gerardo Arteaga", src: require('../assets/MEX/Gerardo Arteaga.jpg')},
                    {name: "Johan Vasquez", src: require('../assets/MEX/Johan Vasquez.jpg')},
                    {name: "Jorge Sanchez", src: require('../assets/MEX/Jorge Sanchez.jpg')}
                ],
                por: [
                    {name: "Guillermo Ochoa", src: require('../assets/MEX/Guillermo Ochoa.jpg')}
                ],
                ban:[
                    {name: "Alfredo Talavera", src: require('../assets/MEX/Alfredo Talavera.jpg')},
                    {name: "Carlos Rodriguez", src: require('../assets/MEX/Carlos Rodriguez.jpg')},
                    {name: "Erick Gutierrez", src: require('../assets/MEX/Erick Gutierrez.jpg')},
                    {name: "Kevin Alvarez", src: require('../assets/MEX/Kevin Alvarez.jpg')},
                    {name: "Luis Chavez", src: require('../assets/MEX/Luis Chavez.jpg')},
                    {name: "Luis Romo", src: require('../assets/MEX/Luis Romo.jpg')},
                    {name: "Rodolfo Cota", src: require('../assets/MEX/Rodolfo Cota.jpg')},
                    {name: "Rogelio Funes Mori", src: require('../assets/MEX/Rogelio Funes Mori.jpg')},
                    {name: "Tata Martino", src: require('../assets/MEX/Tata Martino.jpg')},
                    {name: "Hector Moreno", src: require('../assets/MEX/Hector Moreno.jpg')},
                    {name: "Henry Martin", src: require('../assets/MEX/Henry Martin.jpg')},
                    {name: "Jesus Gallardo", src: require('../assets/MEX/Jesus Gallardo.jpg')},
                    {name: "Nestor Araujo", src: require('../assets/MEX/Nestor Araujo.jpg')},
                    {name: "Orbelin Pineda", src: require('../assets/MEX/Orbelin Pineda.jpg')},
                    {name: "Roberto Alvarado", src: require('../assets/MEX/Roberto Alvarado.jpg')},
                    {name: "Uriel Antuna", src: require('../assets/MEX/Uriel Antuna.jpg')},
                ]
            },
            pol: {
                del: [
                    {name: "Arkadiusz Milik", src: require('../assets/POL/Arkadiusz Milik.jpg')},
                    {name: "Robert Lewandowski", src: require('../assets/POL/Robert Lewandowski.jpg')},
                ],
                med: [
                    {name: "Grzegorz Krychowiak", src: require('../assets/POL/Grzegorz Krychowiak.jpg')},
                    {name: "Krystian Bielik", src: require('../assets/POL/Krystian Bielik.jpg')},
                    {name: "Piotr Zielinski", src: require('../assets/POL/Piotr Zielinski.jpg')},
                    {name: "Sebastian Szymanski", src: require('../assets/POL/Sebastian Szymanski.jpg')}
                ],
                def: [
                    {name: "Bartosz Bereszynski ", src: require('../assets/POL/Bartosz Bereszynski .jpg')},
                    {name: "Jan Bednarek", src: require('../assets/POL/Jan Bednarek.jpg')},
                    {name: "Kamil Glik", src: require('../assets/POL/Kamil Glik.jpg')},
                    {name: "Matty Cash", src: require('../assets/POL/Matty Cash.jpg')}
                ],
                por: [
                    {name: "Wojciech Szczesny", src: require('../assets/POL/Wojciech Szczesny.jpg')}
                ],
                ban:[
                    {name: "Artur Jedrzejczyk", src: require('../assets/POL/Artur Jedrzejczyk.jpg')},
                    {name: "Czeslaw Michniewicz", src: require('../assets/POL/Czeslaw Michniewicz.jpg')},
                    {name: "Damian Szymanski", src: require('../assets/POL/Damian Szymanski.jpg')},
                    {name: "Kamil Grosicki", src: require('../assets/POL/Kamil Grosicki.jpg')},
                    {name: "Karol Swiderski", src: require('../assets/POL/Karol Swiderski.jpg')},
                    {name: "Krzysztof Piatek", src: require('../assets/POL/Krzysztof Piatek.jpg')},
                    {name: "Nicola Zalewski", src: require('../assets/POL/Nicola Zalewski.jpg')},
                    {name: "Przemyslaw Frankowski", src: require('../assets/POL/Przemyslaw Frankowski.jpg')},
                    {name: "Robert Gumny", src: require('../assets/POL/Robert Gumny.jpg')},
                    {name: "Jakub Kaminski", src: require('../assets/POL/Jakub Kaminski.jpg')},
                    {name: "Jakub Kiwior", src: require('../assets/POL/Jakub Kiwior.jpg')},
                    {name: "Kamil Grabara", src: require('../assets/POL/Kamil Grabara.jpg')},
                    {name: "Lukasz Skorupski", src: require('../assets/POL/Lukasz Skorupski.jpg')},
                    {name: "Mateusz Wieteska", src: require('../assets/POL/Mateusz Wieteska.jpg')},
                    {name: "Michal Skoras", src: require('../assets/POL/Michal Skoras.jpg')},
                    {name: "Szymon Zurkowski", src: require('../assets/POL/Szymon Zurkowski.jpg')},
                ]
            }
        }
    },
}
</script>

<style>
</style>