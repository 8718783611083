<template>
    <!-- Footer start -->
    <div class="bg-black w-full flex justify-center items-center md:justify-center flex-col md:flex-row gap-4 p-4 md:gap-8 md:p-8">
        <!-- Overview start -->
        <div class="text-stone-100">
            <p class="tracking-widest text-stone-100 text-xxs md:text-xs p-1">
				El contenido de esta web tiene un carácter humorístico y no busca dañar la imagen de ninguna persona.
			</p>
			<p class="tracking-widest text-stone-100 text-xxs md:text-xs p-1">
				Para ejercer tu derecho a solicitar la retirada de tu imagen de esta página en base a la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de los derechos digitales, por favor contacta con
				<a href="mailto:insultacomounargentino@gmail.com" target="_blank" rel="noopener noreferrer">insultacomounargentino@gmail.com</a>
			</p>
        </div>
        <!-- Overview end -->

        <!-- Links start -->
        <div class="text-stone-100 flex justify-center items-center gap-8">
			<!-- <a href="#">
				<i class="fa-brands fa-instagram socialIcon"></i>
			</a>
			<a href="#">
				<i class="fa-brands fa-linkedin socialIcon"></i>
			</a>
			<a href="#">
				<i class="fa-brands fa-facebook socialIcon"></i>
			</a>
			<a href="#">
				<i class="fa-brands fa-dribbble socialIcon"></i>
			</a> -->
			<a href="https://pacoynolo.com" target="_blank" rel="noopener noreferrer">
				<p class="tracking-widest font-semibold text-stone-100 text-xs p-1">
					pacoynolo.com
				</p>
			</a>
        </div>
        <!-- Links end -->
    </div>
    <!-- Footer end -->
</template>

<script>
export default {

}
</script>

<style>

</style>