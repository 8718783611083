<template>
	<!-- Main section start -->
	<home-section></home-section>
	<!-- Main section end -->

	<!-- About section start -->
	<main-section></main-section>
	<!-- About section end -->
</template>

<script>
import HomeSection from '@/components/HomeSection.vue';
import MainSection from '@/components/MainSection.vue';

export default {
	components: {
		HomeSection,
		MainSection
	}
}
</script>

<style scoped>


</style>