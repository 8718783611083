<template>
    <!-- Navbar start -->
    <nav class="w-full bg-white p-4 lg:px-20 flex justify-between items-center">
		<!-- Logo start -->
		<div class="h-full flex flex-col justify-center">
			<p class="tracking-widest text-lg font-semibold">insultá</p>
		</div>
		<!-- Logo end -->

		<!-- Menu content start -->
		<div class="left-0 right-0 bg-none bg-opacity-0 flex gap-4 items-center p-2 flex-row static shadow-none translate-y-0" role="menu" aria-expanded="false">
			<a href="https://twitter.com/insultacomoARG" target="_blank" rel="noopener noreferrer">
				<i class="fa-brands fa-twitter socialIcon" role="menuitem"></i>
			</a>
			<a href="https://instagram.com/_u/insultacomoARG" target="_blank" rel="noopener noreferrer">
				<i class="fa-brands fa-instagram socialIcon" role="menuitem"></i>
			</a>
			<!-- <button class="btn" role="menuitem">
				Twitter
			</button>
			<button class="btn" role="menuitem">
				Instagram
			</button> -->
			<!-- Links end -->
		</div>
		<!-- Menu content end -->
	</nav>
    <!-- Navbar end -->
</template>

<script>

export default {
}
</script>

<style>

</style>