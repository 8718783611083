<template>
    <!-- Main section start -->
	<section class="px-12 lg:px-32 pt-16 pb-8 lg:pb-16 md:mb-8">
		<div class="w-full flex justify-between ">
			<div class="w-full md:w-1/2 min-h-seventy flex flex-col md:justify-center ">
				<!-- Main text start -->
				<h3 class="text-3xl xl:text-5xl mb-4 font-semibold ">
					El álbum de cromos de
				</h3>
				<h1 class="text-4xl xl:text-7xl mb-4 font-semibold ">
					Insultá como
					un argentino
				</h1>
				<p class="text-lg mt-5">
					Elegí tu selección favorita en los botones a continuación y descargá tu cromo haciendo click en su imagen
				</p>
				<!-- Main text end -->
			</div>
			<div class="hidden md:flex flex-col justify-center items-center">
				<img src="@/assets/banner.png" style="max-width: 45%; border-radius: 50%;"/>
			</div>
			<!-- Social links start -->
			<!-- <div class="flex mt-3 gap-8">
				<a href="#">
					<i class="fa-brands fa-instagram socialIcon"></i>
				</a>
				<a href="#">
					<i class="fa-brands fa-linkedin socialIcon"></i>
				</a>
				<a href="#">
					<i class="fa-brands fa-facebook socialIcon"></i>
				</a>
				<a href="#">
					<i class="fa-brands fa-dribbble socialIcon"></i>
				</a>
				<a href="#">
					<i class="fa-brands fa-pinterest socialIcon"></i>
				</a>
			</div> -->
			<!-- Social links end -->
		</div>

		<!-- Photographer image end -->

		<!-- "More" button start -->
		<!-- <div class="w-full h-20 hidden lg:flex justify-center items-center">
			<a href="#main-section">
				<i class="fa-solid fa-arrow-down transition text-stone-100 text-md lg:text-xl bg-black rounded-full shadow p-4 hover:-translate-y-2 hover:bg-stone-500"></i>
			</a>
		</div> -->
		<!-- "More" button end -->

	</section>
	<!-- Main section end -->
</template>

<script>
export default {

}
</script>

<style>
html {
  scroll-behavior: smooth;
}
</style>